<template>
  <div class="row">
    <div class="col-md-12">
      <div class="bgc-white bd bdrs-3 p-20 mB-20">
        <h2 class="c-grey-900 mB-20" style="display: inline-block">
          {{ $t("employees") }}
        </h2>
        <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
        <button class="btn btn-sm btn-success" @click="addEmployee">
          <i class="ti-plus mL-5" style="color: #fff"></i>{{ $t('addEmployee') }}
        </button>
        <div id="dataTable_wrapper" class="dataTables_wrapper">
          <form @submit="checkForm">
            <div class="form-group row pB-30 pT-30">
              <div class="col-sm-3">
                <input
                  type="text"
                  class="form-control"
                  v-model="name"
                  :placeholder="this.$t('employeeName')"
                />
              </div>
              <div class="col-sm-3">
                <input
                  type="text"
                  min="1"
                  class="form-control"
                  v-model="mobileNumber"
                  :placeholder="this.$t('employeeMobileNumber')"
                />
              </div>
              <div v-if="branches.length != 0" class="form-group col-md-3">
            <select id="inputState" class="form-control" v-model="branch_id" :placeholder="$t('branch')">
              <option value="" disabled selected>{{ $t('employeeBranch') }}</option>
              <option  value="">{{ $t('all') }}</option>
              <option  v-for="item in branches" :key="item.id" :value="item.id">{{ getTranslation(item.ar_name , item.en_name) }}</option>
            </select>
          </div>
              <div class="col-sm-2">
                <button
                  type="submit"
                  class="btn btn-primary mL-10"
                  :disabled="loading"
                >
                  {{ $t("search") }}
                </button>
              </div>
            </div>
          </form>
          <table
            id="dataTable"
            class="table table-bordered dataTable table-hover"
            role="grid"
            aria-describedby="dataTable_info"
            style="width: 100%"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr role="row">
                <th
                  class="sorting_asc"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-sort="ascending"
                  aria-label="Name: activate to sort column descending"
                >
                  #
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Position: activate to sort column ascending"
                >
                  {{ $t("name") }}
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Office: activate to sort column ascending"
                >
                  {{ $t("mobile_number") }}
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Office: activate to sort column ascending"
                >
                  {{ $t("branch") }}
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Age: activate to sort column ascending"
                >
                  {{ $t("role") }}
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 30px"
                  aria-label="Start date: activate to sort column ascending"
                >
                  {{ $t("status") }}
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 30px"
                  aria-label="Start date: activate to sort column ascending"
                >
                  {{ $t('edit') }}
                </th>
              </tr>
            </thead>
            <tbody v-show="!loading" v-for="item in employees" :key="item.id">
              <tr role="row" class="odd">
                <td class="sorting_1">{{ item.id }}</td>
                <td>
                  {{item.name }}
                </td>
                <td style="direction: ltr;">
                  {{ item.user.credential}}
                </td>
                <td style="direction: ltr;">
                  {{ getTranslation(item.branch.ar_name , item.branch.en_name)}}
                </td>
                <td>
                  {{getTranslation(item.employee_type.ar_name,item.employee_type.en_name)}}
                </td>
                <td v-if="item.user.status_id == 1" @click="changeStatus(item)">
                  <a href="javascript:"
                    ><span
                      class="
                        badge
                        bgc-green-50
                        c-green-700
                        p-10
                        lh-0
                        tt-c
                        badge-pill
                      "
                      >{{ $t("active") }}</span
                    ></a
                  >
                </td>
                <td v-if="item.user.status_id != 1" @click="changeStatus(item)">
                  <a href="javascript:"
                    ><span
                      class="
                        badge badge-pill
                        bgc-red-50
                        c-red-700
                        p-10
                        lh-0
                        tt-c
                        badge-pill
                      "
                      >{{ $t("disactive") }}</span
                    ></a
                  >
                </td>
                <td>
                  <a href="javascript:"
                  @click="editEmployee(item)"
                    ><span class="badge rounded-pill bgc-amber-50 c-amber-700"
                      ><i class="ti-pencil"></i></span
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <loading v-show="loading" />
          <p v-show="!employees.length" style="text-align: center">
            {{ $t("noData") }}
          </p>
          <div style="text-align:center;margin-top:40px">
            <pagination style="display:inline-block;text-align:center" v-model="page" :records="pagination.total ?pagination.total : 0 " :per-page="40" @paginate="getEmployees"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      statusLoading: true,
      name: "",
      mobileNumber: "",
      branch_id: "",
      employees: [],
      pagination: {},
      branches: [],
      page:1,
    };
  },
  created() {
    this.getEmployees();
  },
  methods: {
    getTranslation(ar , en) {
      return localStorage.getItem('lang') == 'ar' ? ar : en;
    },
    changeStatus: function (item) {
      this.statusLoading = true;
      item.user.status_id = item.user.status_id == 1 ? 2 : 1;

      let headers = {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      };
      this.$http
        .put(
          "/backend/employees/active",
          {
          employee_id :item.id,
          status_id: item.user.status_id
          },
          { headers }
        )

        .then(
          function (data) {
            this.statusLoading = false;
            switch (data.status) {
              case 200:
                this.success(this.$t("operationStatusSuccess"));
                break;
            }
          },
          (err) => {
            item.user.status_id = item.user.status_id == 1 ? 2 : 1;
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("operationStatusFailed"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.loading = false;
                this.error(this.$t("unexpectedError"));
            }
          }
        );
    },
    checkForm: function (e) {
      e.preventDefault();
      this.getEmployees();
    },
    getEmployees: function () {
      this.loading = true;
      this.$http
        .get("/backend/employees/get", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          params: {
            name : this.name,
            mobile_number : this.mobileNumber,
            branch_id : this.branch_id
          },
        })
        .then(
          function (data) {
            this.loading = false;
            switch (data.status) {
              case 200:
                this.employees = data.body.employees.data;
                this.branches = data.body.branches;
                this.pagination = data.body.employees;
                console.log(this.pagination);
                break;
            }
          },
          (err) => {
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
                break;
            }
          }
        );
    },
    addEmployee: function () {
      const panel1Handle = this.$showPanel({
        component: () => import("../../views/employees/AddEmployee.vue"),
        openOn: "right",
        width: 700,
        disableBgClick: true,
        props: {
          reload:this.getEmployees,
          branches: this.branches
        },
      });
    },
    editEmployee(employee) {
      const panel1Handle = this.$showPanel({
        component: () => import("../../views/employees/EditEmployee.vue"),
        openOn: "right",
        width: 700,
        disableBgClick: true,
        props: {
          employee: employee,
          reload:this.getEmployees,
          branches: this.branches
        },
      });

      panel1Handle.promise.then((result) => {});
    },
    error: function (message) {
      this.$toast.error(message, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    warning: function (message) {
      this.$toast.warning(message, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    success: function (message) {
      this.$toast.success(message, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>

<style>
</style>